import React from "react"
import Card from "react-bootstrap/Card"
import {
  Button,
  Container,
  Row,
  Col,
  Breadcrumb,
  ListGroup,
  Navbar,
  Nav,
  Modal,
  Tab,
  Form,
  FormControl,
  Accordion,
  Table,
} from "react-bootstrap"
import {
  FaArrowAltCircleRight,
  FaArrowRight,
  FaArrowsAltH,
} from "react-icons/fa"
import bookimage from "../../image/BBFB.png"
import { BiSolidRightArrow } from "react-icons/bi"
import { MdAirlineSeatReclineExtra } from "react-icons/md"
import { PiClockCountdownFill } from "react-icons/pi"
import { AiOutlineSearch } from "react-icons/ai"
import { AiFillFacebook } from "react-icons/ai"
import { AiFillTwitterCircle } from "react-icons/ai"
import { GiEarthAfricaEurope } from "react-icons/gi"
import { RiRestartFill } from "react-icons/ri"
import { Stepper, Step } from "react-form-stepper"
import "./Summary.css"
import { useNavigate } from "react-router-dom"
import AccordianBox from "../AccordianPart/AccordianPart"
import FinalFooter from "../FinalFooter/FinalFooter"
import { PHONE_NUMBER } from "../../constants"

const Summary = () => {
  const navigate = useNavigate()

  const basicDetails: any = (JSON.parse(
    sessionStorage.getItem("flightDetails") || "{}",
  ) || [])[0]

  const dictionaries: any =
    JSON.parse(sessionStorage.getItem("dictionaries") || "{}") || {}

  const tripType = sessionStorage.getItem("tripType")

  const selectedAmount = sessionStorage.getItem("selectedAmount")

  const flight = sessionStorage.getItem("flight")

  const selectedType = sessionStorage.getItem("selectedType")

  const startTime = !basicDetails
    ? "00:00"
    : [
        new Date(
          basicDetails["itineraries"][0]["segments"][0]["departure"]["at"],
        )
          ?.toLocaleTimeString()
          .toLowerCase()
          .split(":")[0],
        new Date(
          basicDetails["itineraries"][0]["segments"][0]["departure"]["at"],
        )
          ?.toLocaleTimeString()
          .toLowerCase()
          .split(":")[1],
      ].join(":") +
      " " +
      new Date(basicDetails["itineraries"][0]["segments"][0]["departure"]["at"])
        ?.toLocaleTimeString()
        .toLowerCase()
        .split(" ")[1]

  const endTime = !basicDetails
    ? "00:00"
    : [
        new Date(
          basicDetails["itineraries"][0]["segments"][
            basicDetails["itineraries"][0]["segments"]?.length - 1
          ]["arrival"]["at"],
        )
          ?.toLocaleTimeString()
          .toLowerCase()
          .split(":")[0],
        new Date(
          basicDetails["itineraries"][0]["segments"][
            basicDetails["itineraries"][0]["segments"]?.length - 1
          ]["arrival"]["at"],
        )
          ?.toLocaleTimeString()
          .toLowerCase()
          .split(":")[1],
      ].join(":") +
      " " +
      new Date(
        basicDetails["itineraries"][0]["segments"][
          basicDetails["itineraries"][0]["segments"]?.length - 1
        ]["arrival"]["at"],
      )
        ?.toLocaleTimeString()
        .toLowerCase()
        .split(" ")[1]

  return (
    <>
      <section className="summary-header">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="trip-summary py-5">
                <div
                  className="trip-header"
                  style={{ border: "1px solid black" }}
                >
                  <div className="book-image">
                    <h1
                      style={{
                        lineHeight: "1",
                        fontWeight: "normal",
                        padding: "2%",
                        margin: "2%",
                      }}
                    >
                      Thank you for connecting us
                    </h1>
                    <br />
                    <br />
                    <h1 style={{ lineHeight: "1", fontWeight: "normal" }}>
                      Our customer representative will call you shortly from
                      this number {PHONE_NUMBER}
                    </h1>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default Summary
