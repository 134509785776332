import React from "react"
import { Button, Container, Row, Col, Breadcrumb, ListGroup, Navbar, Nav, Modal, Tab, Form, FormControl, Accordion, Table, } from "react-bootstrap";
import { PiClockCountdownFill } from 'react-icons/pi'
import { AiFillFacebook } from 'react-icons/ai'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { GiEarthAfricaEurope } from 'react-icons/gi'
import './FinalFooter.css'

const FinalFooter = ({ functionToBeCalled, text }: any) => {
    return (
        <>
            <section className="terms-condition py-5">
                <Container>
                    <div className="pay-section pb-5">
                        <div className="time">
                            <div className="service-time">
                                <div className="clock-icon">
                                    <PiClockCountdownFill />
                                </div>
                                <div className="service-text">
                                    <h5>24 HR RISK-FREE</h5>
                                    <p>CANCELLATION</p>
                                </div>
                            </div>
                        </div>
                        <div className="pay-button">
                            <a onClick={functionToBeCalled} href="#" className="button">{text}</a>
                        </div>
                    </div>

                    <div className="condition">
                        <div className="condition-text-1">
                            <h3>Terms and Conditions</h3>
                        </div>
                        <div className="condition-text-2">
                            <h5>See Terms Below.</h5>
                        </div>
                    </div>
                    <div className="condition-list py-4">
                        <h6 style={{ paddingBottom: '8px' }}>GENERAL CONDITIONS OF PURCHASE</h6>
                        <p>You must accept the following terms and conditions in order to complete your booking. For full details, please review our International Contract of Carriageopens in a new window.</p>
                        <p>Once your ticket is purchased, Risk-Free Cancellationopens in a new window may apply. No contract exists until you receive confirmation that payment was received and processed.</p>
                        <p>In addition, you understand that Air Lines will pass personal data provided in connection with this booking to government authorities for security purposes. I accept the Privacy Policyopens in a new window.</p>
                        <p>View Fare Rules, Change & Cancellation Policies.opens in a new popup This ticket is changeable / refundableopens in a new popup. Fees may apply. If you do not show up for any flight in your itinerary without notifying us or changing/cancelling your flight before it departs, we will cancel all remaining flights in the itinerary and the ticket will have no remaining value.</p>
                        <p>The advertised price is not an offer and is subject to change. All prices are (USD) unless otherwise noted. You may see separate transaction to your credit card based on the vendors and the products you are purchasing. Amounts may vary depending on the rate of exchange at time of transaction. Hazardous Materialsopens in a new popup, In-Flight services and amenities may vary and are subject to change.</p>
                    </div>
                    <div className="baggage">
                        <h6>BAGGAGE ALLOWANCE</h6>
                        <p style={{ paddingTop: '6px' }}>Final baggage fees will be assessed and charged at time of check in. By making this booking, you understand that flammables, batteries, explosives, and aerosols are prohibited from being included in all baggage. For full details, please review our Baggage Policyopens in a new window.</p>
                    </div>
                </Container>
            </section>
            <section className="footer-section py-5">
                <div className="copyright-content" style={{ textAlign: 'center', color: '#7d8388' }}>
                    <p>© 2023 Air Lines, Inc. | Travel may be on other airline.</p>
                    <p>Terms and conditions apply to all offers and Airline benefits. See specific offer for details, and visit <span className="heading-color" style={{ color: 'white' }}>Airline Membership Guide & Program Rules</span></p>
                </div>
                <div className="media-icon">
                    <div className="social-media">
                        <ListGroup horizontal>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: 'none', color: 'white', fontSize: '30px' }}><AiFillFacebook /></ListGroup.Item>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: 'none', color: 'white', fontSize: '30px' }}><AiFillTwitterCircle /></ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="language">
                        <ListGroup horizontal>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: 'none', color: 'white', }}><span className="icon"><GiEarthAfricaEurope /></span>VACATION DEALS</ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            </section>
        </>
    )
}
export default FinalFooter
