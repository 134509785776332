import {
	configureStore,
	ThunkAction,
	Action,
	combineReducers,
} from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rtkQueryErrorLogger } from "../services/middlewares/errorMiddleware";
import flightsSlice from "../services/slices/flights";
import { flightsApi } from "../services/flightServices";

const persistConfig = {
	key: "root",
	whitelist: ["profile"],
	version: 1,
	storage,
};

const rootReducer = combineReducers({
	counter: counterReducer,
	flight: flightsSlice,
	[flightsApi.reducerPath]: flightsApi.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat([
			rtkQueryErrorLogger,
			flightsApi.middleware
		]),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
