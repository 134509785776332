import React from "react"
import popupimage from "../image/popupnew.jpg"
import topHeader from "../image/topheaderimg.webp"
import mobilebottom from "../image/mobilebottom.webp"
import restwebsitedelta from "../image/restwebsitedelta.webp"
import phone from "../image/phoneRotate.png"
import { useNavigate } from "react-router-dom"
import { AiOutlineCloseCircle } from "react-icons/ai"
import "./Landing.css"
import { PHONE_NUMBER, SLUG } from "../constants"

export const LandingPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <div>
        <img src={popupimage} onClick={() => navigate("/popup")} />
        <div>
          <a href={`tel:${PHONE_NUMBER}`} style={{ textDecoration: "none" }}>
            <p
              style={{
                fontSize: "28px",
                textAlign: "center",
                fontWeight: " bold",
                color: "white",
                borderRadius: "30px",
                backgroundColor: "#111729",
                marginLeft: "10px",
                marginRight: "10px",
                paddingTop: "10px",
                paddingBottom: "15px",
              }}
            >
              <img
                style={{ height: "30px", width: "30px" }}
                className="ringing-phone"
                src={phone}
              />{" "}
              {PHONE_NUMBER}
            </p>
          </a>
        </div>
      </div>
      <div
        className="topheaderair"
        onClick={() => navigate("/popup")}
      ></div>
      <div className="topheasder" onClick={() => navigate("/popup")}>
        <img src={topHeader} />
      </div>

      <div className="banner-top">
        <a href={`tel:${PHONE_NUMBER}`} style={{ textDecoration: "none" }}>
          <img src={mobilebottom} />
        </a>
      </div>

      <div onClick={() => navigate("/popup")}>
        <div className="bottom-sec">
          <img src={restwebsitedelta} />
        </div>
      </div>
      <div className="chat-container">
        <a href={`tel:${PHONE_NUMBER}`} className="chat-button">
          <img className="ringing-phone" src={phone} />
        </a>
      </div>
    </>
  )
}
