import React, { useState } from "react"
import { Container, Row, Col, ListGroup, Table } from "react-bootstrap"
import bookimage from "../../image/BBFB.png"
import { MdAirlineSeatReclineExtra, MdOutlineCases } from "react-icons/md"
import "./FinalPage.css"
import AccordianBox from "../AccordianPart/AccordianPart"
import FinalFooter from "../FinalFooter/FinalFooter"
import { useNavigate } from "react-router-dom"
import { useSendEmailMutation } from "../../services/flightServices"
import "./FinalPage.css"

const ReviewAndPay = () => {
  const navigate = useNavigate()
  const selectedAmount = sessionStorage.getItem("selectedAmount")

  const tripType = sessionStorage.getItem("tripType")

  const flight = sessionStorage.getItem("flight")

  const name = sessionStorage.getItem("name")

  const phone = sessionStorage.getItem("phone")

  const selectedFromAirportCode = sessionStorage.getItem(
    "selectedFromAirportCode",
  )

  const selectedToAirportCode = sessionStorage.getItem("selectedToAirportCode")

  const startDate = sessionStorage.getItem("startDate")

  const [firstname1, setfirstname1] = useState("")
  const [middleName1, setMiddleName1] = useState("")
  const [lastName1, setLastName1] = useState("")

  const [dobD1, setDobD1] = useState("")
  const [dobM1, setDobM1] = useState("")
  const [dobY1, setDobY1] = useState("")

  const [gender1, setGender1] = useState("")

  const [suffix1, setSuffix1] = useState("")

  const [firstname2, setfirstname2] = useState("")
  const [middleName2, setMiddleName2] = useState("")
  const [lastName2, setLastName2] = useState("")

  const [dobD2, setDobD2] = useState("")
  const [dobM2, setDobM2] = useState("")
  const [dobY2, setDobY2] = useState("")

  const [gender2, setGender2] = useState("")

  const [suffix2, setSuffix2] = useState("")

  const [emergencyFirstName, setEmergencyFirstName] = useState("")
  const [emergencyLastname, setEmergencyLastName] = useState("")
  const [emergencyPhoneNo, setEmergencyPhoneNo] = useState("")

  const [creditCardNo, setCreditCardNo] = useState("")
  const [expiry, setExpiry] = useState("")

  const [nameOnCard, setNameOnCard] = useState("")
  const [cardCity, setCardCity] = useState("")
  const [cardState, setCardState] = useState("")
  const [CardPostalCode, setCardPostalCode] = useState("")
  const [cardCountry, setCardCountry] = useState("")
  const [add1, setAdd1] = useState("")
  const [add2, setAdd2] = useState("")

  const passengerCount = +(sessionStorage.getItem("passengerCount") || "1")

  const [submitForm] = useSendEmailMutation()

  const handleFinalSubmit = () => {
    if (creditCardNo && expiry) {
      submitForm({
        subject: "Mail From Step 4 - Credit Card Step Filled",
        jsonData: {
          selectedFromAirportCode,
          selectedToAirportCode,
          tripType,
          flightNo: flight,
          startDate,
          name,
          phone,
          amount: selectedAmount,

          firstname: firstname1,
          middleName: middleName1,
          lastName: lastName1,
          dob: `${dobD1}-${dobM1}-${dobY1}`,
          gender: gender1,
          suffix: suffix1,

          twofirstname: firstname2,
          twomiddleName: middleName2,
          twolastName: lastName2,
          twodob: `${dobD2}-${dobM2}-${dobY2}`,
          twogender: gender2,
          twosuffix: suffix2,

          creditCardNo: creditCardNo.substring(-4),
          expiry,
          nameOnCard,
          cardCity,
          cardCountry,
          cardState,
          CardPostalCode,
        },
      })
      navigate("/thankyou")
    } else {
      window.alert(
        "Credit card details are a must, please fill them to proceed",
      )
    }
  }

  return (
    <>
      <section className="summary-header">
        <Container>
          <Row>
            <Col xs={12} md={9}>
              <div className="trip-summary">
                <div className="trip-header">
                  <div className="book-image">
                    <img src={bookimage} />
                    <h1
                      style={{
                        lineHeight: "1",
                        fontWeight: "bold",
                        fontSize: "xx-large",
                      }}
                    >
                      Review & Pay
                    </h1>
                  </div>
                </div>
              </div>
              <div className="sub-head">
                <h6>Changeable / Refundable</h6>
              </div>
              <div
                className="view-seat"
                style={{ margin: "1%", border: "1px solid rgb(11, 31, 102)" }}
              >
                <div className="select-seat py-2">
                  <ListGroup horizontal>
                    <ListGroup.Item
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      <span className="icons" style={{ fontSize: "30px" }}>
                        <MdOutlineCases />
                      </span>{" "}
                      Baggage Allowance (Per Person)
                    </ListGroup.Item>
                  </ListGroup>
                </div>
                <div className="view-map">
                  <div className="view px-4 py-3">
                    <Table>
                      <thead>
                        <tr>
                          <th>Outbound</th>
                          <th>Carry-On</th>
                          <th>1st Bag</th>
                          <th>2nd Bag</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* <td>{ basicDetails && basicDetails['itineraries'][0]['segments'][0]['departure']['iataCode']}-{basicDetails['itineraries'][0]['segments'][basicDetails['itineraries'][0]['segments']?.length - 1]['arrival']['iataCode']}</td> */}
                          <td>Free</td>
                          <td>
                            Free<sub>(50lbs/23kg)</sub>
                          </td>
                          <td>Free</td>
                          <td>
                            <sup>$</sup>100.00<sup>USD</sup>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="view-map">
                  <div className="view px-4 py-3">
                    <div className="map-button">
                      <MdOutlineCases />
                    </div>
                    <div className="map-heading">
                      <p>
                        Traveling with multiple bags or have special items like
                        golf clubs or overweight/oversized baggage?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="view-seat my-5">
                <div className="form-detail">
                  <div className="heading-form">
                    <h2>Prime Passenger Details</h2>
                  </div>
                  <form
                    className="formbox"
                    style={{ border: "1px solid grey", color: 'black' }}
                  >
                    <div className="form-row-form">
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">First Name</label>
                        <input
                          type="text"
                          value={firstname1}
                          onChange={(e) => setfirstname1(e.target.value)}
                          className="form-control"
                          id="inputEmail4"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">
                          Middle Name (optional)
                        </label>
                        <input
                          type="text"
                          value={middleName1}
                          onChange={(e) => setMiddleName1(e.target.value)}
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Middle Name"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">Last Name</label>
                        <input
                          type="text"
                          value={lastName1}
                          onChange={(e) => setLastName1(e.target.value)}
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Last Name"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inlineFormCustomSelectPref">
                          Suffix
                        </label>
                        <div className="flexbox-row">
                          <select
                            className="form-control custom-select-form col-md-4"
                            id="inlineFormCustomSelectPref"
                            onChange={(e) => setSuffix1(e.target.value)}
                            value={suffix1}
                          >
                            <option value="--">--</option>
                            <option value="Jr">Jr</option>
                            <option value="Sr">Sr</option>
                            <option value="I">I</option>
                            <option value="II">II</option>
                            <option value="III">III</option>
                            <option value="CLU">CLU</option>
                            <option value="CO">CO</option>
                            <option value="CPA">CPA</option>
                            <option value="DDS">DDS</option>
                            <option value="ESQ">ESQ</option>
                            <option value="IV">IV</option>
                            <option value="MD">MD</option>
                            <option value="PhD">PhD</option>
                            <option value="RN">RN</option>
                            <option value="V">V</option>
                            <option value="VI">VI</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inlineFormCustomSelectPref">
                          Date of Birth
                        </label>
                        <div className="flexbox-row">
                          <select
                            className="form-control custom-select col-md-4"
                            id="inlineFormCustomSelectPref"
                            onChange={(e: any) => setDobM1(e.target.value)}
                            value={dobM1}
                          >
                            <option value="">MM</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                          <select
                            className="form-control custom-select col-md-4"
                            id="inlineFormCustomSelectPref"
                            onChange={(e: any) => setDobD1(e.target.value)}
                            value={dobD1}
                          >
                            <option value="">DD</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </select>
                          <select
                            className="form-control custom-select col-md-4"
                            id="inlineFormCustomSelectPref"
                            onChange={(e: any) => setDobY1(e.target.value)}
                            value={dobY1}
                          >
                            <option value="">YYYY</option>
                            <option value="1900">1900</option>
                            <option value="1901">1901</option>
                            <option value="1902">1902</option>
                            <option value="1903">1903</option>
                            <option value="1904">1904</option>
                            <option value="1905">1905</option>
                            <option value="1906">1906</option>
                            <option value="1907">1907</option>
                            <option value="1908">1908</option>
                            <option value="1909">1909</option>
                            <option value="1910">1910</option>
                            <option value="1911">1911</option>
                            <option value="1912">1912</option>
                            <option value="1913">1913</option>
                            <option value="1914">1914</option>
                            <option value="1915">1915</option>
                            <option value="1916">1916</option>
                            <option value="1917">1917</option>
                            <option value="1918">1918</option>
                            <option value="1919">1919</option>
                            <option value="1920">1920</option>
                            <option value="1921">1921</option>
                            <option value="1922">1922</option>
                            <option value="1923">1923</option>
                            <option value="1924">1924</option>
                            <option value="1925">1925</option>
                            <option value="1926">1926</option>
                            <option value="1927">1927</option>
                            <option value="1928">1928</option>
                            <option value="1929">1929</option>
                            <option value="1930">1930</option>
                            <option value="1931">1931</option>
                            <option value="1932">1932</option>
                            <option value="1933">1933</option>
                            <option value="1934">1934</option>
                            <option value="1935">1935</option>
                            <option value="1936">1936</option>
                            <option value="1937">1937</option>
                            <option value="1938">1938</option>
                            <option value="1939">1939</option>
                            <option value="1940">1940</option>
                            <option value="1941">1941</option>
                            <option value="1942">1942</option>
                            <option value="1943">1943</option>
                            <option value="1944">1944</option>
                            <option value="1945">1945</option>
                            <option value="1946">1946</option>
                            <option value="1947">1947</option>
                            <option value="1948">1948</option>
                            <option value="1949">1949</option>
                            <option value="1950">1950</option>
                            <option value="1951">1951</option>
                            <option value="1952">1952</option>
                            <option value="1953">1953</option>
                            <option value="1954">1954</option>
                            <option value="1955">1955</option>
                            <option value="1956">1956</option>
                            <option value="1957">1957</option>
                            <option value="1958">1958</option>
                            <option value="1959">1959</option>
                            <option value="1960">1960</option>
                            <option value="1961">1961</option>
                            <option value="1962">1962</option>
                            <option value="1963">1963</option>
                            <option value="1964">1964</option>
                            <option value="1965">1965</option>
                            <option value="1966">1966</option>
                            <option value="1967">1967</option>
                            <option value="1968">1968</option>
                            <option value="1969">1969</option>
                            <option value="1970">1970</option>
                            <option value="1971">1971</option>
                            <option value="1972">1972</option>
                            <option value="1973">1973</option>
                            <option value="1974">1974</option>
                            <option value="1975">1975</option>
                            <option value="1976">1976</option>
                            <option value="1977">1977</option>
                            <option value="1978">1978</option>
                            <option value="1979">1979</option>
                            <option value="1980">1980</option>
                            <option value="1981">1981</option>
                            <option value="1982">1982</option>
                            <option value="1983">1983</option>
                            <option value="1984">1984</option>
                            <option value="1985">1985</option>
                            <option value="1986">1986</option>
                            <option value="1987">1987</option>
                            <option value="1988">1988</option>
                            <option value="1989">1989</option>
                            <option value="1990">1990</option>
                            <option value="1991">1991</option>
                            <option value="1992">1992</option>
                            <option value="1993">1993</option>
                            <option value="1994">1994</option>
                            <option value="1995">1995</option>
                            <option value="1996">1996</option>
                            <option value="1997">1997</option>
                            <option value="1998">1998</option>
                            <option value="1999">1999</option>
                            <option value="2000">2000</option>
                            <option value="2001">2001</option>
                            <option value="2002">2002</option>
                            <option value="2003">2003</option>
                            <option value="2004">2004</option>
                            <option value="2005">2005</option>
                            <option value="2006">2006</option>
                            <option value="2007">2007</option>
                            <option value="2008">2008</option>
                            <option value="2009">2009</option>
                            <option value="2010">2010</option>
                            <option value="2011">2011</option>
                            <option value="2012">2012</option>
                            <option value="2013">2013</option>
                            <option value="2014">2014</option>
                            <option value="2015">2015</option>
                            <option value="2016">2016</option>
                            <option value="2017">2017</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                            <option value="2031">2031</option>
                            <option value="2032">2032</option>
                            <option value="2033">2033</option>
                            <option value="2034">2034</option>
                            <option value="2035">2035</option>
                            <option value="2036">2036</option>
                            <option value="2037">2037</option>
                            <option value="2038">2038</option>
                            <option value="2039">2039</option>
                            <option value="2040">2040</option>
                            <option value="2041">2041</option>
                            <option value="2042">2042</option>
                            <option value="2043">2043</option>
                            <option value="2044">2044</option>
                            <option value="2045">2045</option>
                            <option value="2046">2046</option>
                            <option value="2047">2047</option>
                            <option value="2048">2048</option>
                            <option value="2049">2049</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inlineFormCustomSelectPref">
                          Gender
                        </label>
                        <div className="flexbox-row">
                          <select
                            className="form-control custom-select-form col-md-4"
                            id="inlineFormCustomSelectPref"
                            onChange={(e) => setGender1(e.target.value)}
                            value={gender1}
                          >
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                  {passengerCount > 1 ? (
                    <label
                      style={{ fontWeight: "bold", paddingBottom: "2%" }}
                      htmlFor="inputEmail4"
                    >
                      Passenger 2
                    </label>
                  ) : null}
                  {passengerCount > 1 ? (
                    <form
                      className="formbox"
                      style={{ border: "1px solid grey" }}
                    >
                      <div className="form-row-form">
                        <div className="form-group col-md-4">
                          <label htmlFor="inputEmail4">First Name</label>
                          <input
                            type="text"
                            value={firstname2}
                            onChange={(e) => setfirstname2(e.target.value)}
                            className="form-control"
                            id="inputEmail4"
                            placeholder="First Name"
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputEmail4">
                            Middle Name (optional)
                          </label>
                          <input
                            type="text"
                            value={middleName2}
                            onChange={(e) => setMiddleName2(e.target.value)}
                            className="form-control"
                            id="inputEmail4"
                            placeholder="Middle Name"
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputEmail4">Last Name</label>
                          <input
                            type="text"
                            value={lastName2}
                            onChange={(e) => setLastName2(e.target.value)}
                            className="form-control"
                            id="inputEmail4"
                            placeholder="Last Name"
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inlineFormCustomSelectPref">
                            Suffix
                          </label>
                          <div className="flexbox-row">
                            <select
                              className="form-control custom-select-form col-md-4"
                              id="inlineFormCustomSelectPref"
                              onChange={(e) => setSuffix2(e.target.value)}
                              value={suffix2}
                            >
                              <option value="--">--</option>
                              <option value="Jr">Jr</option>
                              <option value="Sr">Sr</option>
                              <option value="I">I</option>
                              <option value="II">II</option>
                              <option value="III">III</option>
                              <option value="CLU">CLU</option>
                              <option value="CO">CO</option>
                              <option value="CPA">CPA</option>
                              <option value="DDS">DDS</option>
                              <option value="ESQ">ESQ</option>
                              <option value="IV">IV</option>
                              <option value="MD">MD</option>
                              <option value="PhD">PhD</option>
                              <option value="RN">RN</option>
                              <option value="V">V</option>
                              <option value="VI">VI</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inlineFormCustomSelectPref">
                            Date of Birth
                          </label>
                          <div className="flexbox-row">
                            <select
                              className="form-control custom-select col-md-4"
                              id="inlineFormCustomSelectPref"
                              onChange={(e: any) => setDobM2(e.target.value)}
                              value={dobM2}
                            >
                              <option value="">MM</option>
                              <option value="01">January</option>
                              <option value="02">February</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">August</option>
                              <option value="09">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select>
                            <select
                              className="form-control custom-select col-md-4"
                              id="inlineFormCustomSelectPref"
                              onChange={(e: any) => setDobD2(e.target.value)}
                              value={dobD2}
                            >
                              <option value="">DD</option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                            </select>
                            <select
                              className="form-control custom-select col-md-4"
                              id="inlineFormCustomSelectPref"
                              onChange={(e: any) => setDobY2(e.target.value)}
                              value={dobY2}
                            >
                              <option value="">YYYY</option>
                              <option value="1900">1900</option>
                              <option value="1901">1901</option>
                              <option value="1902">1902</option>
                              <option value="1903">1903</option>
                              <option value="1904">1904</option>
                              <option value="1905">1905</option>
                              <option value="1906">1906</option>
                              <option value="1907">1907</option>
                              <option value="1908">1908</option>
                              <option value="1909">1909</option>
                              <option value="1910">1910</option>
                              <option value="1911">1911</option>
                              <option value="1912">1912</option>
                              <option value="1913">1913</option>
                              <option value="1914">1914</option>
                              <option value="1915">1915</option>
                              <option value="1916">1916</option>
                              <option value="1917">1917</option>
                              <option value="1918">1918</option>
                              <option value="1919">1919</option>
                              <option value="1920">1920</option>
                              <option value="1921">1921</option>
                              <option value="1922">1922</option>
                              <option value="1923">1923</option>
                              <option value="1924">1924</option>
                              <option value="1925">1925</option>
                              <option value="1926">1926</option>
                              <option value="1927">1927</option>
                              <option value="1928">1928</option>
                              <option value="1929">1929</option>
                              <option value="1930">1930</option>
                              <option value="1931">1931</option>
                              <option value="1932">1932</option>
                              <option value="1933">1933</option>
                              <option value="1934">1934</option>
                              <option value="1935">1935</option>
                              <option value="1936">1936</option>
                              <option value="1937">1937</option>
                              <option value="1938">1938</option>
                              <option value="1939">1939</option>
                              <option value="1940">1940</option>
                              <option value="1941">1941</option>
                              <option value="1942">1942</option>
                              <option value="1943">1943</option>
                              <option value="1944">1944</option>
                              <option value="1945">1945</option>
                              <option value="1946">1946</option>
                              <option value="1947">1947</option>
                              <option value="1948">1948</option>
                              <option value="1949">1949</option>
                              <option value="1950">1950</option>
                              <option value="1951">1951</option>
                              <option value="1952">1952</option>
                              <option value="1953">1953</option>
                              <option value="1954">1954</option>
                              <option value="1955">1955</option>
                              <option value="1956">1956</option>
                              <option value="1957">1957</option>
                              <option value="1958">1958</option>
                              <option value="1959">1959</option>
                              <option value="1960">1960</option>
                              <option value="1961">1961</option>
                              <option value="1962">1962</option>
                              <option value="1963">1963</option>
                              <option value="1964">1964</option>
                              <option value="1965">1965</option>
                              <option value="1966">1966</option>
                              <option value="1967">1967</option>
                              <option value="1968">1968</option>
                              <option value="1969">1969</option>
                              <option value="1970">1970</option>
                              <option value="1971">1971</option>
                              <option value="1972">1972</option>
                              <option value="1973">1973</option>
                              <option value="1974">1974</option>
                              <option value="1975">1975</option>
                              <option value="1976">1976</option>
                              <option value="1977">1977</option>
                              <option value="1978">1978</option>
                              <option value="1979">1979</option>
                              <option value="1980">1980</option>
                              <option value="1981">1981</option>
                              <option value="1982">1982</option>
                              <option value="1983">1983</option>
                              <option value="1984">1984</option>
                              <option value="1985">1985</option>
                              <option value="1986">1986</option>
                              <option value="1987">1987</option>
                              <option value="1988">1988</option>
                              <option value="1989">1989</option>
                              <option value="1990">1990</option>
                              <option value="1991">1991</option>
                              <option value="1992">1992</option>
                              <option value="1993">1993</option>
                              <option value="1994">1994</option>
                              <option value="1995">1995</option>
                              <option value="1996">1996</option>
                              <option value="1997">1997</option>
                              <option value="1998">1998</option>
                              <option value="1999">1999</option>
                              <option value="2000">2000</option>
                              <option value="2001">2001</option>
                              <option value="2002">2002</option>
                              <option value="2003">2003</option>
                              <option value="2004">2004</option>
                              <option value="2005">2005</option>
                              <option value="2006">2006</option>
                              <option value="2007">2007</option>
                              <option value="2008">2008</option>
                              <option value="2009">2009</option>
                              <option value="2010">2010</option>
                              <option value="2011">2011</option>
                              <option value="2012">2012</option>
                              <option value="2013">2013</option>
                              <option value="2014">2014</option>
                              <option value="2015">2015</option>
                              <option value="2016">2016</option>
                              <option value="2017">2017</option>
                              <option value="2018">2018</option>
                              <option value="2019">2019</option>
                              <option value="2020">2020</option>
                              <option value="2021">2021</option>
                              <option value="2022">2022</option>
                              <option value="2023">2023</option>
                              <option value="2024">2024</option>
                              <option value="2025">2025</option>
                              <option value="2026">2026</option>
                              <option value="2027">2027</option>
                              <option value="2028">2028</option>
                              <option value="2029">2029</option>
                              <option value="2030">2030</option>
                              <option value="2031">2031</option>
                              <option value="2032">2032</option>
                              <option value="2033">2033</option>
                              <option value="2034">2034</option>
                              <option value="2035">2035</option>
                              <option value="2036">2036</option>
                              <option value="2037">2037</option>
                              <option value="2038">2038</option>
                              <option value="2039">2039</option>
                              <option value="2040">2040</option>
                              <option value="2041">2041</option>
                              <option value="2042">2042</option>
                              <option value="2043">2043</option>
                              <option value="2044">2044</option>
                              <option value="2045">2045</option>
                              <option value="2046">2046</option>
                              <option value="2047">2047</option>
                              <option value="2048">2048</option>
                              <option value="2049">2049</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inlineFormCustomSelectPref">
                            Gender
                          </label>
                          <div className="flexbox-row">
                            <select
                              className="form-control custom-select-form col-md-4"
                              id="inlineFormCustomSelectPref"
                              onChange={(e) => setGender2(e.target.value)}
                              value={gender2}
                            >
                              <option value="">Select</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : null}
                  <form
                    className="formbox"
                    style={{ border: "1px solid grey" }}
                  >
                    <div className="heading-form">
                      <h2>Emergency</h2>
                    </div>
                    <div className="form-row-form">
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="First Name"
                          value={emergencyFirstName}
                          onChange={(e) =>
                            setEmergencyFirstName(e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">
                          Middle Name (optional)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Middle Name"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Last Name"
                          value={emergencyLastname}
                          onChange={(e) => setEmergencyLastName(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Phone"
                          value={emergencyPhoneNo}
                          onChange={(e) => setEmergencyPhoneNo(e.target.value)}
                        />
                      </div>
                    </div>
                  </form>
                  <form
                    className="formbox"
                    style={{ border: "1px solid grey" }}
                  >
                    <div className="heading-form">
                      <h2>Credit / Debit Card</h2>
                    </div>
                    <div className="form-row-form">
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">Card Number</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Card Number"
                          value={creditCardNo}
                          onChange={(e) => setCreditCardNo(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">Expiration Date</label>
                        <div className="flexbox-row">
                          <input
                            required
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            placeholder="MM/YY"
                            value={expiry}
                            onChange={(e) => setExpiry(e.target.value)}
                          />
                          <input
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            placeholder="CVV"
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">Name on Card</label>
                        <input
                          value={nameOnCard}
                          onChange={(e) => setNameOnCard(e.target.value)}
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Name on Card"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">Country</label>
                        <input
                          value={cardCountry}
                          onChange={(e) => setCardCountry(e.target.value)}
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Country"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">Address Line 1</label>
                        <input
                          value={add1}
                          onChange={(e) => setAdd1(e.target.value)}
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Address"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">Address Line 2</label>
                        <input
                          value={add2}
                          onChange={(e) => setAdd2(e.target.value)}
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Address"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">City</label>
                        <input
                          value={cardCity}
                          onChange={(e) => setCardCity(e.target.value)}
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="City"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">State</label>
                        <input
                          value={cardState}
                          onChange={(e) => setCardState(e.target.value)}
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="State"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputEmail4">Postal Code</label>
                        <input
                          value={CardPostalCode}
                          onChange={(e) => setCardPostalCode(e.target.value)}
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Code"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="trip-summary ">
                <div className="trip-header">
                  <div className="book-image"></div>
                </div>
                <div className="accordion-box">
                  <div className="view-seat ">
                    <div className="select-seat">
                      <ListGroup horizontal>
                        <ListGroup.Item
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            fontSize: "30px",
                            fontWeight: "bold",
                          }}
                        >
                          <span
                            className="icons"
                            style={{ fontSize: "40px", fontWeight: "bold" }}
                          ></span>
                          Trip Total
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                    <div className="view-map">
                      <div className="view px-4 py-3">
                        <div className="map-heading" style={{ paddingBottom: '0 !important' }}>
                          <p>Flights: ${selectedAmount}</p>
                        </div>
                      </div>
                      <div className="view px-4">
                        <div className="map-heading">
                          <p>Taxes, Fees and Charges: $50.00</p>
                        </div>
                      </div>
                    </div>
                    <div className="select-seat py-2"></div>
                    <div className="view-map">
                      <div
                        className="view px-4 py-3"
                        style={{
                          border: "1px solid black",
                        }}
                      >
                        <div className="map-heading">
                          <p style={{ color: "#6495ED", fontWeight: "bolder" }}>
                            Amount Due
                          </p>
                        </div>
                        <div className="map-button">
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "#00008B",
                              fontSize: "20px",
                            }}
                          >
                            ${+(selectedAmount || 0) + 50}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <FinalFooter
        functionToBeCalled={() => {
          handleFinalSubmit()
        }}
        text="COMPLETE PURCHASE"
      />
    </>
  )
}
export default ReviewAndPay
