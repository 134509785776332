import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import {LandingPage} from "./Component/Landing"
import FlightChart from "./Component/FlightChart/FlightChart"
import Summary from "./Component/Summary/Summary"
import MainWrapper from "./Component/MainWrapper"
import ReviewAndPay from "./Component/FinalPage/FinalPage"
import { Popup } from "./Component/Popup"
import { SLUG } from "./constants"
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainWrapper />}>
          <Route index element={<LandingPage />} />
          <Route path="summary" element={<Summary />} />
          <Route path="popup" element={<Popup />} />
          <Route path="flights" element={<FlightChart />} />
          <Route path="landing" element={<LandingPage />} />
          <Route path="review" element={<ReviewAndPay />} />
          <Route path="thankyou" element={<Summary />} />
        </Route>
        <Route path="/book-flight" element={<MainWrapper />}>
          <Route index element={<LandingPage />} />
          <Route path="summary" element={<Summary />} />
          <Route path="popup" element={<Popup />} />
          <Route path="flights" element={<FlightChart />} />
          <Route path="landing" element={<LandingPage />} />
          <Route path="review" element={<ReviewAndPay />} />
          <Route path="thankyou" element={<Summary />} />
        </Route>
        <Route path="/booking-flight" element={<MainWrapper />}>
          <Route index element={<LandingPage />} />
          <Route path="summary" element={<Summary />} />
          <Route path="popup" element={<Popup />} />
          <Route path="flights" element={<FlightChart />} />
          <Route path="landing" element={<LandingPage />} />
          <Route path="review" element={<ReviewAndPay />} />
          <Route path="thankyou" element={<Summary />} />
        </Route>
        <Route path="/book-flight-cheap" element={<MainWrapper />}>
          <Route index element={<LandingPage />} />
          <Route path="summary" element={<Summary />} />
          <Route path="popup" element={<Popup />} />
          <Route path="flights" element={<FlightChart />} />
          <Route path="landing" element={<LandingPage />} />
          <Route path="review" element={<ReviewAndPay />} />
          <Route path="thankyou" element={<Summary />} />
        </Route>
        <Route path="/book-flight-easy" element={<MainWrapper />}>
          <Route index element={<LandingPage />} />
          <Route path="summary" element={<Summary />} />
          <Route path="popup" element={<Popup />} />
          <Route path="flights" element={<FlightChart />} />
          <Route path="landing" element={<LandingPage />} />
          <Route path="review" element={<ReviewAndPay />} />
          <Route path="thankyou" element={<Summary />} />
        </Route>
        <Route path="/book-flight-on-call" element={<MainWrapper />}>
          <Route index element={<LandingPage />} />
          <Route path="summary" element={<Summary />} />
          <Route path="popup" element={<Popup />} />
          <Route path="flights" element={<FlightChart />} />
          <Route path="landing" element={<LandingPage />} />
          <Route path="review" element={<ReviewAndPay />} />
          <Route path="thankyou" element={<Summary />} />
        </Route>
        <Route path="/booking-flight-both" element={<MainWrapper />}>
          <Route index element={<LandingPage />} />
          <Route path="summary" element={<Summary />} />
          <Route path="popup" element={<Popup />} />
          <Route path="flights" element={<FlightChart />} />
          <Route path="landing" element={<LandingPage />} />
          <Route path="review" element={<ReviewAndPay />} />
          <Route path="thankyou" element={<Summary />} />
        </Route>
        <Route path="/flight-booking" element={<MainWrapper />}>
          <Route index element={<LandingPage />} />
          <Route path="summary" element={<Summary />} />
          <Route path="popup" element={<Popup />} />
          <Route path="flights" element={<FlightChart />} />
          <Route path="landing" element={<LandingPage />} />
          <Route path="review" element={<ReviewAndPay />} />
          <Route path="thankyou" element={<Summary />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
