import React from "react"
import { Col, Row } from "react-bootstrap"
import { Outlet } from "react-router-dom"
import Header from "./Header/Header"

const MainWrapper = () => {

  return (
    <>
      <Row>
        <Col>
          <Header />
        </Col>
      </Row>
      <Row>
        {" "}
        <Col>
          {" "}
          <Outlet />
        </Col>{" "}
      </Row>
    </>
  )
}

export default MainWrapper
