import React, { useEffect } from "react"
import { useState } from "react"
import {
  Button,
  Container,
  Nav,
  Modal,
  Tab,
  Form,
  FormControl,
} from "react-bootstrap"

import {
  useGetAirportsQuery,
  useSendEmailMutation,
} from "../services/flightServices"
import { AiOutlineCloseCircle } from "react-icons/ai"
import "react-datepicker/dist/react-datepicker.css"
import { AiOutlineArrowRight } from "react-icons/ai"

import { useNavigate } from "react-router-dom"

import deltaformarrow from "../image/deltaformarrow.svg"
import "./Popup.css"
import { SLUG } from "../constants"

export const PASSENGERS_OPTIONS = [
  { count: 1, title: "1 Passenger" },
  { count: 2, title: "2 Passengers" },
  { count: 3, title: "3 Passengers" },
  { count: 4, title: "4 Passengers" },
  { count: 5, title: "5 Passengers" },
  { count: 6, title: "6 Passengers" },
  { count: 7, title: "7 Passengers" },
  { count: 8, title: "8 Passengers" },
  { count: 9, title: "9 Passengers" },
]

export const Popup = () => {
  const navigate = useNavigate()
  const [fromShow, setFromShow] = useState(false)
  const handlefromClose = () => setFromShow(false)
  const [toShow, setToShow] = useState(false)
  const handleToClose = () => setToShow(false)

  const [searchedFromValue, setFromSearchedValue] = useState("")
  const [searchedToValue, setToSearchedValue] = useState("")

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  const [selectedFromAirportCode, setSelectedFromAirportCode] = useState("FROM")
  const [selectedFromLocation, setSelectedFromLocation] = useState("")
  const [selectedFromCountryCode, setSelectedFromCountryCode] = useState("")

  const [selectedToAirportCode, setSelectedToAirportCode] = useState("TO")
  const [selectedToLocation, setSelectedToLocation] = useState("")
  const [selectedToCountryCode, setSelectedToCountryCode] = useState("")

  const { data: airportsFromList } = useGetAirportsQuery(searchedFromValue)

  const { data: airportsToList } = useGetAirportsQuery(searchedToValue)

  const [submitForm] = useSendEmailMutation()

  const handleNextPage = () => {
    if (!name && !phone) {
      window.alert("Please fill in your name and contact number to continue")
    } else if (!name) {
      window.alert("Please fill in your name to continue")
    } else if (!phone) {
      window.alert("Please fill in all your contact number to continue")
    } else if (phone && (phone.length < 10 || phone.length > 12)) {
      window.alert("Please fill in a valid contact number")
    } else if (
      selectedFromAirportCode === "FROM" ||
      selectedToAirportCode === "TO"
    ) {
      window.alert("Please fill in the proper destination")
    } else if (
      selectedFromAirportCode &&
      selectedToAirportCode &&
      tripType &&
      startDate &&
      phone &&
      name
    ) {
      console.log({
        bject: "Mail From Step 1",
        jsonData: {
          selectedFromAirportCode,
          selectedToAirportCode,
          tripType,
          startDate,
          name,
          phone,
          endDate,
          passengerCount,
        },
      })
      submitForm({
        subject: "Mail From Step 1",
        jsonData: {
          selectedFromAirportCode,
          selectedToAirportCode,
          tripType,
          startDate,
          name,
          phone,
          endDate,
          passengerCount,
        },
      })
      sessionStorage.setItem("selectedFromAirportCode", selectedFromAirportCode)
      sessionStorage.setItem("selectedToAirportCode", selectedToAirportCode)
      sessionStorage.setItem("tripType", tripType)
      sessionStorage.setItem("startDate", startDate)
      sessionStorage.setItem("endDate", endDate)
      sessionStorage.setItem("phone", phone)
      sessionStorage.setItem("name", name)
      sessionStorage.setItem("passengerCount", passengerCount)
      navigate("/flights")
    } else {
      window.alert("Please fill in all your details to continue")
    }
  }

  const [passengerCount, setPassengerCount] = useState("1 Passenger")

  const [tripType, setTripType] = useState("Round Trip")

  const [fromAirports, setFromAirports] = useState([])
  const [toAirports, setToAirports] = useState([])

  useEffect(() => {
    if (airportsFromList?.data?.data?.length) {
      const listingData = airportsFromList?.data?.data
      const listing = listingData.map((airport: any) => ({
        code: airport.iataCode,
        location: airport.name,
        country: airport.address.countryCode,
      }))
      setFromAirports(listing)
    } else {
      setFromAirports([])
    }
  }, [airportsFromList])

  useEffect(() => {
    if (airportsToList?.data?.data?.length) {
      const listingData = airportsToList?.data?.data
      const listing = listingData.map((airport: any) => ({
        code: airport.iataCode,
        location: airport.name,
        country: airport.address.countryCode,
      }))
      setToAirports(listing)
    } else {
      setToAirports([])
    }
  }, [airportsToList])

  // const [startDate, setStartDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)

  return (
    <>
      <div className="popupbox">
        <a onClick={() => navigate(SLUG + "/")}>
          <AiOutlineCloseCircle
            style={{
              fontSize: "29px",
              color: " #fff",
              margin: "17px 0 0 27px",
              position: "absolute",
            }}
          />
        </a>
        <div className="row modalbox">
          <div className="col-5 col-md-2 padding-none">
            <div className="autocomplete-wrapper">
              <input
                style={{ color: "white" }}
                onClick={() => setFromShow(true)}
                autoComplete="off"
                id="getAirport1"
                className="form-control ng-untouched ng-pristine ng-valid"
                name="from_ap1"
                placeholder="FROM"
                type="text"
                data-gtm-form-interact-field-id="0"
                value={selectedFromAirportCode}
              />
              <div className="autocomplete-results" data-highlight="0"></div>
              {selectedFromLocation
                ? `${selectedFromLocation}, ${selectedFromCountryCode}`
                : ""}
            </div>
          </div>
          <img
            src={deltaformarrow}
            style={{ width: "16%", marginTop: "-13px", marginLeft: "4px" }}
          ></img>
          <div className="col-4 col-md-2 padding-none">
            <div className="autocomplete-wrapper-two">
              <input
                style={{ color: "white", textAlign: "right" }}
                onClick={() => setToShow(true)}
                autoComplete="off"
                id="getAirport2"
                className="form-control ng-untouched ng-pristine ng-invalid"
                name="to_ap1"
                placeholder="TO"
                value={selectedToAirportCode}
                type="text"
              />
              <div className="autocomplete-results-two"></div>
              {selectedToLocation
                ? `${selectedToLocation}, ${selectedToCountryCode}`
                : ""}
            </div>
            <span className="xIcon"></span>
          </div>
          <div
            className="col-12 col-md-2 padding-none"
            style={{ marginTop: " 20px", marginBottom: "20px" }}
          >
            <select
              className="form-control"
              onChange={(e) => {
                setTripType(e.target.value)
              }}
              style={{
                color: "#fff",
                padding: "14px 8px",
                width: "85%",
                borderRadius: "0px",
                backgroundColor: "#11172b",
                paddingBottom: "10px",
                paddingTop: "10px",
                marginLeft: "15px",
                fontSize: "20px",
                fontWeight: "100 !important",
                paddingLeft: "0px",
                border: "none",
                borderBottom: "1px solid white",
              }}
            >
              <option value="Round Trip">Round Trip</option>
              <option value="One Way">One Way</option>
            </select>
          </div>
          <div className="flexrow" style={{ marginBottom: "20px" }}>
            <div
              className="col-5 col-md-2 padding-none depart-rt"
              style={{
                borderBottom: "1px solid #ffffff9c",
                marginLeft: " 15px",
              }}
            >
              <label
                style={{
                  padding: "5px",
                  fontWeight: "100",
                  position: "relative",
                  color: "#fff",
                }}
              >
                Depart
              </label>
              <input
                style={{
                  padding: "5px",
                  fontWeight: "100",
                  position: "relative",
                  color: "#fff",
                }}
                autoComplete="off"
                className="form-control hasDatepicker"
                placeholder="Depart"
                name="date_ap1"
                id="datedepart"
                type="date"
                onChange={(e) => {
                  setStartDate(e.target.value)
                }}
              />
            </div>
            {/* <span style={{ color: " #cc363b", paddingTop: "17px" }}>--- </span> */}
            {!!tripType.includes("ound") ? (
              <div
                className="col-5 col-md-2 padding-none depart-rt"
                style={{
                  borderBottom: "1px solid #ffffff9c",
                  marginLeft: " 15px",
                }}
              >
                <label
                  style={{
                    padding: "5px",
                    fontWeight: "100",
                    position: "relative",
                    color: "#fff",
                  }}
                >
                  Return
                </label>
                <input
                  style={{
                    padding: "5px",
                    fontWeight: "100",
                    position: "relative",
                    color: "#fff",
                  }}
                  autoComplete="off"
                  className="form-control hasDatepicker"
                  placeholder="Depart"
                  name="date_ap1"
                  id="datedepart"
                  type="date"
                  onChange={(e) => {
                    setEndDate(e.target.value)
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            className="col-12 col-md-2 padding-none"
            style={{ marginTop: " 20px", marginBottom: "20px" }}
          >
            <select
              onChange={(e) => setPassengerCount(e.target.value)}
              className="form-control"
              style={{
                color: "#fff !important",
                padding: "14px 8px",
                width: "85%",
                borderRadius: "0px",
                backgroundColor: "#11172b",
                paddingBottom: "10px",
                paddingTop: "10px",
                marginLeft: "15px",
                fontSize: "20px",
                fontWeight: "100 !important",
                paddingLeft: "0px",
                border: "none",
                borderBottom: "1px solid white",
              }}
            >
              {PASSENGERS_OPTIONS?.map((opt: any) => (
                <option value={opt.count}>{opt.title}</option>
              ))}
            </select>
          </div>
          <div
            className="col-12 col-md-2 padding-none"
            style={{ marginBottom: "20px" }}
          >
            <input
              required
              autoComplete="off"
              style={{
                color: "#fff !important",
                width: "85%",
                borderRadius: "0px",
                backgroundColor: "#11172b",
                borderBottom: "1px solid #ffffff9c",
                paddingBottom: "10px",
                paddingTop: "10px",
                marginLeft: "15px",
                fontSize: "20px",
                fontWeight: "100 !important",
                paddingLeft: "0px",
              }}
              className="form-control ng-untouched ng-pristine ng-invalid"
              placeholder="Name"
              name="name"
              id="txtname"
              type="text"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
            <span id="name_err" style={{ color: "red" }}></span>
          </div>
          <div className="col-12 col-md-2 padding-none">
            <input
              autoComplete="off"
              style={{
                color: "white !important",
                width: "85%",
                borderRadius: "0px",
                backgroundColor: "#11172b",
                borderBottom: "1px solid #ffffff9c",
                paddingBottom: "10px",
                paddingTop: "10px",
                marginLeft: "15px",
                fontSize: "20px",
                fontWeight: "100 !important",
                paddingLeft: "0px",
              }}
              className="form-control ng-untouched ng-pristine ng-invalid"
              placeholder="Phone"
              name="Phone"
              id="txtname"
              type="number"
              minLength={10}
              maxLength={12}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <span id="name_err" style={{ color: "red" }}></span>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="d-flex justify-content-center px-auto pt-2 text-muted"
              onClick={handleNextPage}
              style={{
                fontSize: "1.5rem",
                marginBottom: "-1.6rem",
                border: " 1px solid gray",
                height: "60px",
                borderRadius: "50%",
                width: "60px",
                color: "#fff",
                alignItems: "center",
                margin: "36px 0 0 0",
                background: "#cc363b",
              }}
              variant="outline-secondary"
            >
              <AiOutlineArrowRight style={{ color: "#fff" }} />
            </Button>
          </div>{" "}
        </div>
      </div>
      <Modal
        className="airportFromModal"
        show={fromShow}
        onHide={handlefromClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Tab.Container id="your-tabs" defaultActiveKey="first">
            <Container>
              <Nav variant="tabs" className="mb-3">
                <Nav.Item style={{ border: 0 }}>
                  <Nav.Link eventKey="first">Search</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <label className="orgin-label">Origin</label>
                  <Form>
                    <FormControl
                      style={{ color: "black !important" }}
                      className="form-control"
                      type="text"
                      placeholder="City or Airport"
                      defaultValue={searchedFromValue}
                      onChange={(e) => setFromSearchedValue(e.target.value)}
                    />
                  </Form>
                </Tab.Pane>
                <div className="modalDropdown">
                  <ul className="airportsList">
                    {!!fromAirports.length &&
                      fromAirports.map((airport: any) => {
                        return (
                          <li
                            className="airport"
                            onClick={() => {
                              setSelectedFromAirportCode(airport.code)
                              setSelectedFromCountryCode(airport.country)
                              setSelectedFromLocation(airport.location)
                              handlefromClose()
                            }}
                          >
                            <a className="airportLookup-list">
                              <span className="airport-code">
                                {airport.code}
                              </span>
                              <span className="airport-city">
                                {airport.location}
                              </span>
                            </a>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </Tab.Content>
            </Container>
          </Tab.Container>
        </Modal.Body>
      </Modal>{" "}
      <Modal className="airportToModal" show={toShow} onHide={handleToClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Tab.Container id="your-tabs" defaultActiveKey="first">
            <Container>
              <Nav variant="tabs" className="mb-3">
                <Nav.Item style={{ border: 0 }}>
                  <Nav.Link eventKey="first">Search</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <label className="orgin-label">Origin</label>
                  <Form>
                    <FormControl
                      type="text"
                      placeholder="City or Airport"
                      defaultValue={searchedToValue}
                      onChange={(e) => setToSearchedValue(e.target.value)}
                    />
                  </Form>
                </Tab.Pane>
                <div className="modalDropdown">
                  <ul className="airportsList">
                    {!!toAirports.length &&
                      toAirports.map((airport: any) => {
                        return (
                          <li
                            className="airport"
                            onClick={() => {
                              setSelectedToAirportCode(airport.code)
                              setSelectedToCountryCode(airport.country)
                              setSelectedToLocation(airport.location)
                              handleToClose()
                            }}
                          >
                            <a className="airportLookup-list">
                              <span className="airport-code">
                                {airport.code}
                              </span>
                              <span className="airport-city">
                                {airport.location}
                              </span>
                            </a>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </Tab.Content>
            </Container>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  )
}
