import React, { useEffect } from "react"
import "./FlightCard.css"
import {
  Container,
  Row,
  Col,
  ListGroup,
  Modal,
  Button,
  Accordion,
} from "react-bootstrap"
import { useState } from "react"
import { Step, Stepper } from "react-form-stepper"
import { FaArrowsAltH } from "react-icons/fa"
import { RxDividerVertical } from "react-icons/rx"
import "./ModalDetails.css"
import { useNavigate } from "react-router-dom"
import { useSendEmailMutation } from "../../services/flightServices"
import { SLUG } from "../../constants"

const FlightCard = (props: any) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const navigate = useNavigate()

  const basicDetails = props.details[0]

  const [selectedAmount, setSelectedAmount] = useState(
    basicDetails.price.grandTotal,
  )
  const [selectedType, setSelectedType] = useState(
    basicDetails.type === "BASIC"
      ? "Main"
      : basicDetails.type === "MAIN"
      ? "Comfort+"
      : basicDetails.type === "COMFORT"
      ? "Premium Select"
      : "Airline One",
  )

  const tripType = sessionStorage.getItem("tripType")

  const name = sessionStorage.getItem("name")

  const phone = sessionStorage.getItem("phone")

  const selectedFromAirportCode = sessionStorage.getItem(
    "selectedFromAirportCode",
  )

  const selectedToAirportCode = sessionStorage.getItem("selectedToAirportCode")

  const startDate = sessionStorage.getItem("startDate")

  const endDate = sessionStorage.getItem("endDate")

  const pricing: any = {}

  const [flightOptions, setFlightOptions] = useState([])

  useEffect(() => {
    if (props.details?.length) {
      const flightsFiltered: any = []
      const options: any = {
        BASIC: 0,
        MAIN: 0,
        COMFORT: 0,
        FIRST: 0,
      }
      props.details?.forEach((flight: any) => {
        if (!options[flight.type]) {
          flightsFiltered.push(flight)
          options[flight.type] = 1
        }
      })
      flightsFiltered.forEach((flight: any) => {
        pricing[flight["type"]] = `$${flight["price"]["grandTotal"]}`
      })

      setFlightOptions(flightsFiltered)
    }
  }, [props.details])

  const startTime = [
    new Date(basicDetails["itineraries"][0]["segments"][0]["departure"]["at"])
      ?.toLocaleTimeString()
      .toLowerCase()
      .split(":")[0],
    new Date(basicDetails["itineraries"][0]["segments"][0]["departure"]["at"])
      ?.toLocaleTimeString()
      .toLowerCase()
      .split(":")[1],
  ].join(":")

  const endTime = [
    new Date(
      basicDetails["itineraries"][0]["segments"][
        basicDetails["itineraries"][0]["segments"]?.length - 1
      ]["arrival"]["at"],
    )
      ?.toLocaleTimeString()
      .toLowerCase()
      .split(":")[0],
    new Date(
      basicDetails["itineraries"][0]["segments"][
        basicDetails["itineraries"][0]["segments"]?.length - 1
      ]["arrival"]["at"],
    )
      ?.toLocaleTimeString()
      .toLowerCase()
      .split(":")[1],
  ].join(":")

  const returnStartTime = [
    new Date(basicDetails["itineraries"][1]?.["segments"][0]["departure"]["at"])
      ?.toLocaleTimeString()
      .toLowerCase()
      .split(":")[0],
    new Date(basicDetails["itineraries"][1]?.["segments"][0]["departure"]["at"])
      ?.toLocaleTimeString()
      .toLowerCase()
      .split(":")[1],
  ].join(":")

  const returnEndTime = [
    new Date(
      basicDetails["itineraries"][1]?.["segments"][
        basicDetails["itineraries"][1]["segments"]?.length - 1
      ]["arrival"]["at"],
    )
      ?.toLocaleTimeString()
      .toLowerCase()
      .split(":")[0],
    new Date(
      basicDetails["itineraries"][1]?.["segments"][
        basicDetails["itineraries"][1]["segments"]?.length - 1
      ]["arrival"]["at"],
    )
      ?.toLocaleTimeString()
      .toLowerCase()
      .split(":")[1],
  ].join(":")

  const returnFlight = basicDetails["itineraries"][1]?.["segments"]
    ?.map((seg: any) => `${seg.carrierCode}${seg.number}`)
    .join("-")

  // const [submitForm] = useSendEmailMutation()

  return (
    <>
      <div className="seat-number py-3">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="flexbox">
                <div className="flight-heading-1">
                  <div className="ticket-cart">
                    <div className="card-tag">
                      <h1 className="heading">
                        {props.flight.split("-")?.length == 2
                          ? "ONE STOP"
                          : props.flight.split("-")?.length == 3
                          ? "TWO STOP"
                          : props.flight.split("-")?.length == 4
                          ? "THREE STOP"
                          : "NON STOP"}
                      </h1>
                    </div>
                    <div className="flight-info d-flex justify-content-between py-3 px-3">
                      <div className="flight-name">
                        <p>{props.flight}</p>
                      </div>
                      <div className="flight-time">
                        <p style={{ color: "green", fontWeight: "bold" }}>
                          {basicDetails["itineraries"][0]["duration"]
                            .split("PT")[1]
                            ?.toLowerCase()}
                        </p>
                      </div>
                    </div>
                    <div className="flight-timing d-flex justify-content-between py-3 px-3">
                      {basicDetails["itineraries"][0]["segments"]?.map(
                        (segment: any) => {
                          return (
                            <>
                              <Stepper
                                activeStep={1}
                                style={{
                                  width: "100%",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                <Step
                                  label={`${[
                                    new Date(segment["departure"]?.["at"] || "")
                                      .toLocaleTimeString()
                                      .toLowerCase()
                                      .split(":")[0],
                                    new Date(segment["departure"]?.["at"] || "")
                                      .toLocaleTimeString()
                                      .toLowerCase()
                                      .split(":")[1],
                                  ].join(":")}\n${
                                    segment["departure"]["iataCode"]
                                  }`}
                                />
                                <Step
                                  label={`${[
                                    new Date(segment["arrival"]?.["at"] || "")
                                      .toLocaleTimeString()
                                      .toLowerCase()
                                      .split(":")[0],
                                    new Date(segment["arrival"]?.["at"] || "")
                                      .toLocaleTimeString()
                                      .toLowerCase()
                                      .split(":")[1],
                                  ].join(":")}\n${
                                    segment["arrival"]["iataCode"]
                                  }`}
                                />
                              </Stepper>
                            </>
                          )
                        },
                      )}
                    </div>
                    {!!tripType?.includes("ound") ? (
                      <>
                        <div className="flight-info d-flex justify-content-between py-3 px-3">
                          <div className="flight-name">
                            <p>{returnFlight}</p>
                          </div>
                          <div className="flight-time">
                            <p style={{ color: "green", fontWeight: "bold" }}>
                              {basicDetails["itineraries"][1]["duration"]
                                .split("PT")[1]
                                ?.toLowerCase()}
                            </p>
                          </div>
                        </div>
                        <div className="flight-timing d-flex justify-content-between py-3 px-3">
                          {basicDetails["itineraries"][1]["segments"]?.map(
                            (segment: any) => {
                              return (
                                <>
                                  <Stepper
                                    activeStep={1}
                                    style={{
                                      width: "100%",
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Step
                                      label={`${[
                                        new Date(
                                          segment["departure"]?.["at"] || "",
                                        )
                                          .toLocaleTimeString()
                                          .toLowerCase()
                                          .split(":")[0],
                                        new Date(
                                          segment["departure"]?.["at"] || "",
                                        )
                                          .toLocaleTimeString()
                                          .toLowerCase()
                                          .split(":")[1],
                                      ].join(":")}\n${
                                        segment["departure"]["iataCode"]
                                      }`}
                                    />
                                    <Step
                                      label={`${[
                                        new Date(
                                          segment["arrival"]?.["at"] || "",
                                        )
                                          .toLocaleTimeString()
                                          .toLowerCase()
                                          .split(":")[0],
                                        new Date(
                                          segment["arrival"]?.["at"] || "",
                                        )
                                          .toLocaleTimeString()
                                          .toLowerCase()
                                          .split(":")[1],
                                      ].join(":")}\n${
                                        segment["arrival"]["iataCode"]
                                      }`}
                                    />
                                  </Stepper>
                                </>
                              )
                            },
                          )}
                        </div>
                      </>
                    ) : null}
                    <div className="destination">
                      <div className="card-box">
                        <div className="card-flex d-flex justify-content-between py-2">
                          <ListGroup horizontal>
                            <ListGroup.Item
                              onClick={() => {
                                handleShow()
                                sessionStorage.setItem("flightNo", props.flight)
                                // submitForm({
                                //   subject:
                                //     "Mail From Step 2 - Someone clicked on some flight",
                                //   jsonData: {
                                //     name,
                                //     phone,
                                //     tripType,
                                //     selectedFromAirportCode,
                                //     selectedToAirportCode,
                                //     startDate,
                                //     flightNo: props.flight,
                                //   },
                                // })
                              }}
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                fontSize: "26px",
                                fontWeight: "700",
                                color: "#fff",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "white",
                                  color: "rgb(11, 31, 102)",
                                  padding: "10px 22px",
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "25px",
                                }}
                              >
                                ✈️ Details
                              </div>
                              <div style={{ marginLeft: "10px" }}>
                                ${selectedAmount}
                              </div>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-card">
                  <div className="flight-card text-center">
                    <div className="card-heading">
                      <div className="test">
                        <h6>Main</h6>
                      </div>
                    </div>
                    <div className="card-price">
                      <h6>{pricing["BASIC"] || "Not Available"}</h6>
                      <p>{tripType}</p>
                    </div>
                  </div>
                </div>
                <div className="main-card text-center">
                  <div className="flight-card text-center">
                    <div className="card-heading">
                      <h6>Comfort+</h6>
                    </div>
                    <div className="card-price">
                      <h6>{pricing["MAIN"] || "Not Available"}</h6>
                      <p>{tripType}</p>
                    </div>
                  </div>
                </div>
                <div className="main-card text-center">
                  <div className="flight-card text-center">
                    <div className="card-heading">
                      <h6>Premium Select</h6>
                    </div>
                    <div className="card-price">
                      <h6>{pricing["COMFORT"] || "Not Available"}</h6>
                      <p>{tripType}</p>
                    </div>
                  </div>
                </div>
                <div className="main-card text-center">
                  <div className="flight-card-1 text-center">
                    <div className="card-heading">
                      <h6>Airline One</h6>
                    </div>
                    <div className="card-price">
                      <h6>{pricing["FIRST"] || "Not Available"}</h6>
                      <p>{tripType}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="lg"
        className="details-modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header
          className="nameairport"
          closeButton
          style={{ backgroundColor: "#0b1f66", color: "white" }}
        >
          <div className="header-section">
            <div
              className="header-content"
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <div className="countru-name">
                <div className="place-item">
                  <div className="place-list">
                    <h1>
                      {
                        basicDetails["itineraries"][0]["segments"][0][
                          "departure"
                        ]["iataCode"]
                      }
                    </h1>
                  </div>
                  <div className="arrow-icon">
                    <FaArrowsAltH />
                  </div>
                  <div className="place-list">
                    <h1>
                      {
                        basicDetails["itineraries"][0]["segments"][
                          basicDetails["itineraries"][0]["segments"]?.length - 1
                        ]["arrival"]["iataCode"]
                      }
                    </h1>
                  </div>
                </div>
              </div>

              <div className="time">
                <h5 style={{ fontSize: "16px" }}>
                  {startTime + "-" + endTime}
                </h5>
              </div>
            </div>
          </div>
          {tripType?.includes("ound") ? (
            <div className="header-section">
              <div
                className="header-content"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <div className="countru-name">
                  <div className="place-item">
                    <div className="place-list">
                      <h1>
                        {
                          basicDetails["itineraries"][1]["segments"][0][
                            "departure"
                          ]["iataCode"]
                        }
                      </h1>
                    </div>
                    <div className="arrow-icon">
                      <FaArrowsAltH />
                    </div>
                    <div className="place-list">
                      <h1>
                        {
                          basicDetails["itineraries"][1]["segments"][
                            basicDetails["itineraries"][1]["segments"]?.length -
                              1
                          ]["arrival"]["iataCode"]
                        }
                      </h1>
                    </div>
                  </div>
                </div>

                <div className="time">
                  <h5 style={{ fontSize: "16px" }}>
                    {returnStartTime + "-" + returnEndTime}
                  </h5>
                </div>
              </div>
            </div>
          ) : null}
        </Modal.Header>
        <div className="modal-button">
          <div className="d-grid d-flex">
            {flightOptions?.map((flight: any, index: any) => {
              const text =
                flight.type === "BASIC"
                  ? "Main"
                  : flight.type === "MAIN"
                  ? "Comfort+"
                  : flight.type === "COMFORT"
                  ? "Premium Select"
                  : "Airline One"
              return (
                <>
                  <Button
                    onClick={() => {
                      setSelectedAmount(flight.price.grandTotal)
                      setSelectedType(text)
                    }}
                    size="lg"
                    className={`button-1 ${+index % 2 === 0 ? "py-4" : ""}`}
                  >
                    <h4 style={{ textAlign: "initial" }}>{text}</h4>
                    <p style={{ textAlign: "initial" }}>
                      ${flight.price.grandTotal}
                    </p>
                  </Button>
                </>
              )
            })}
          </div>
        </div>
        <Modal.Body>
          <div className="content-box">
            <Accordion defaultActiveKey="0">
              {basicDetails["itineraries"][0]["segments"]?.map(
                (segment: any, index: any) => {
                  const startTime = [
                    new Date(segment["departure"]["at"])
                      ?.toLocaleTimeString()
                      .toLowerCase()
                      .split(":")[0],
                    new Date(segment["departure"]["at"])
                      ?.toLocaleTimeString()
                      .toLowerCase()
                      .split(":")[1],
                  ].join(":")

                  const endTime = [
                    new Date(segment["arrival"]["at"])
                      ?.toLocaleTimeString()
                      .toLowerCase()
                      .split(":")[0],
                    new Date(segment["arrival"]["at"])
                      ?.toLocaleTimeString()
                      .toLowerCase()
                      .split(":")[1],
                  ].join(":")

                  return (
                    <Accordion.Item eventKey={`${index}Depart`}>
                      <Accordion.Header className="accordion-header">
                        <div className="city-name">
                          <div className="countru-name">
                            <div className="place-items">
                              <div className="place-lists">
                                <h5>{segment["departure"]["iataCode"]}</h5>
                              </div>
                              <div className="arrow-icons">
                                <FaArrowsAltH />
                              </div>
                              <div className="place-lists">
                                <h5>{segment["arrival"]["iataCode"]}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="flight-name">
                            <div className="mt-4 ms-2">
                              {" "}
                              <h5>{segment.carrierCode + segment.number}</h5>
                            </div>
                          </div>
                          <div className="flight-date">
                            {new Date(
                              segment["departure"]["at"] || "",
                            ).toLocaleDateString()}
                          </div>{" "}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="accordion-content">
                          <section className="main-dev">
                            <div className="content-dev">
                              <p>{startTime}</p>
                              <div className="lines">
                                <div className="lines">
                                  <div className="main-content">
                                    <p>{segment["departure"]["iataCode"]}</p>
                                    <p>
                                      {segment.duration
                                        ?.split("PT")[1]
                                        .toLowerCase()}
                                    </p>
                                    <p>{segment["arrival"]["iataCode"]}</p>
                                  </div>
                                </div>
                              </div>
                              <p>{endTime}</p>
                            </div>
                          </section>
                          <div className="main-content-2">
                            <p>
                              {
                                props.dictionaries[
                                  segment["aircraft"]?.["code"]
                                ]
                              }
                            </p>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                },
              )}
              {tripType?.includes("ound") ? <h6>Return</h6> : null}
              {tripType?.includes("ound")
                ? basicDetails["itineraries"][1]["segments"]?.map(
                    (segment: any, index: any) => {
                      const startTime = [
                        new Date(segment["departure"]["at"])
                          ?.toLocaleTimeString()
                          .toLowerCase()
                          .split(":")[0],
                        new Date(segment["departure"]["at"])
                          ?.toLocaleTimeString()
                          .toLowerCase()
                          .split(":")[1],
                      ].join(":")

                      const endTime = [
                        new Date(segment["arrival"]["at"])
                          ?.toLocaleTimeString()
                          .toLowerCase()
                          .split(":")[0],
                        new Date(segment["arrival"]["at"])
                          ?.toLocaleTimeString()
                          .toLowerCase()
                          .split(":")[1],
                      ].join(":")

                      return (
                        <Accordion.Item eventKey={`${index}Return`}>
                          <Accordion.Header className="accordion-header">
                            <div className="city-name">
                              <div className="countru-name">
                                <div className="place-items">
                                  <div className="place-lists">
                                    <h5>{segment["departure"]["iataCode"]}</h5>
                                  </div>
                                  <div className="arrow-icons">
                                    <FaArrowsAltH />
                                  </div>
                                  <div className="place-lists">
                                    <h5>{segment["arrival"]["iataCode"]}</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                alignContent: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div className="flight-name">
                                <div className="mt-4 ms-2">
                                  {" "}
                                  <h5>
                                    {segment.carrierCode + segment.number}
                                  </h5>
                                </div>
                              </div>
                              <div className="flight-date">
                                {new Date(
                                  segment["departure"]["at"] || "",
                                ).toLocaleDateString()}
                              </div>{" "}
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="accordion-content">
                              <section className="main-dev">
                                <div className="content-dev">
                                  <p>{startTime}</p>
                                  <div className="lines">
                                    <div className="main-content">
                                      <p>{segment["departure"]["iataCode"]}</p>
                                      <p>
                                        {segment.duration
                                          ?.split("PT")[1]
                                          .toLowerCase()}
                                      </p>
                                      <p>{segment["arrival"]["iataCode"]}</p>
                                    </div>
                                  </div>
                                  <p>{endTime}</p>
                                </div>
                              </section>
                              <div className="main-content-2">
                                <p>
                                  {
                                    props.dictionaries[
                                      segment["aircraft"]?.["code"]
                                    ]
                                  }
                                </p>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    },
                  )
                : null}
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="ticket-price">
            <h3>${selectedAmount} USD</h3>
          </div>
          <div className="destination detail">
            <p>{tripType} Per Passenger</p>
          </div>
          <div className="select-button">
            <a
              onClick={() => {
                sessionStorage.setItem("selectedAmount", selectedAmount)
                sessionStorage.setItem(
                  "flightDetails",
                  JSON.stringify(flightOptions),
                )
                sessionStorage.setItem(
                  "dictionaries",
                  JSON.stringify(props.dictionaries),
                )
                sessionStorage.setItem("flight", props.flight)
                sessionStorage.setItem("returnFlight", returnFlight)
                sessionStorage.setItem("selectedType", selectedType)

                let jsonDetails = {
                  name,
                  phone,
                  tripType,
                  selectedFromAirportCode,
                  selectedToAirportCode,
                  startDate,
                  flightNo: props.flight,
                  amount: `$${selectedAmount} USD`,
                  flightCategory: selectedType,
                }

                jsonDetails = {
                  ...jsonDetails,
                  ...{
                    returnFlight,
                    endDate,
                  },
                }
                // submitForm({
                //   subject: "Mail From Step 3 - Someone selected some flight",
                //   jsonData: jsonDetails,
                // })
                navigate("/review")
              }}
              className="button"
            >
              SELECT FLIGHT
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FlightCard
