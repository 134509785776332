import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";

export const flightsApi = createApi({
    reducerPath: "flights",
    baseQuery: baseQuery(),
    endpoints: (builder) => ({
        getAirports: builder.query<any, any>({
            query: (data: any) => ({
                url: `/flight-booking/airport-search?loc=${data}`,
                method: "GET",
                data,
            }),
            // offers-search
        }),
        getFlightOffers: builder.query<any, any>({
            query: (data: any) => ({
                url: `/flight-booking/offers-search?selectedFromAirportCode=${data.selectedFromAirportCode}&selectedToAirportCode=${data.selectedToAirportCode}&startDate=${data.startDate}&passengers=${data.passengers}${!data.tripType.includes("ne") ? `&returnDate=${data.endDate}` : ''}`,
                method: "GET",
                data,
            }),
            // offers-search
        }),
        sendEmail: builder.mutation<any, any>({
            query: (data: any) => ({
                url: "/mail/json-data",
                method: "POST",
                data,
            }),
            // offers-search
        }),
    }),
});

export const {
    useGetAirportsQuery,
    useGetFlightOffersQuery,
    useSendEmailMutation
} = flightsApi;
