import React from "react"
import "./FlightChart.css"
import { BiSolidRightArrow } from "react-icons/bi"
import { BsCurrencyDollar } from "react-icons/bs"
import { Container, Row, Col, ListGroup, Dropdown } from "react-bootstrap"
import explorecart from "../../image/banner2.webp"
import { MdAirlineSeatReclineExtra } from "react-icons/md"
import { useGetFlightOffersQuery } from "../../services/flightServices"
import Footer from "../Footer/footer"
import FlightCard from "../FlightCard/FlightCard"
import popupimage from "../../image/popupnew.jpg"
import phone from "../../image/phoneRotate.png"
import { PHONE_NUMBER } from "../../constants"

const FlightPage = (props: any) => {
  const selectedFromAirportCode = sessionStorage.getItem(
    "selectedFromAirportCode",
  )
  const selectedToAirportCode = sessionStorage.getItem("selectedToAirportCode")
  const startDate = sessionStorage.getItem("startDate")
  const endDate = sessionStorage.getItem("endDate")
  const tripType = sessionStorage.getItem("tripType")

  const { data: flightsData } = useGetFlightOffersQuery({
    selectedFromAirportCode,
    selectedToAirportCode,
    startDate,
    tripType,
    endDate,
    passengers: 1,
  })

  return (
    <>
      <section className="comfort py-2">
        <div className="topheaderair">
          <img src={popupimage} />
          <a href={`tel:${PHONE_NUMBER}`}>
            <p
              style={{
                fontSize: "28px",
                textAlign: "center",
                fontWeight: " bold",
                color: "white",
                borderRadius: "30px",
                backgroundColor: "#111729",
                marginLeft: "10px",
                marginRight: "10px",
                paddingTop: "10px",
                paddingBottom: "15px",
              }}
            >
              <img
                style={{ height: "30px", width: "30px" }}
                className="ringing-phone"
                src={phone}
              />{" "}
              {PHONE_NUMBER}
            </p>
          </a>
        </div>
      </section>
      {/* seat-chart */}
      <section className="seat-plan py-3">
        <div className="seat-number">
          <Container>
            <Row>
              <Col xs={12} md={12} style={{ padding: "0" }}>
                <div className="flexbox">
                  <div className="flight-heading-1">
                    <div>
                      <h2>{new Date(startDate || "").toDateString()}</h2>
                      <p style={{ padding: "1%" }}>
                        Fares are per passenger, including taxes and fees.
                        Airline flights may be listed first. Services and
                        amenities may vary or change.
                      </p>
                    </div>
                  </div>
                  <div className="main-card">
                    <div className="borderbox">
                      <h5>Main</h5>
                      <p>Seat(s) assigned after check-in</p>
                    </div>
                  </div>
                  <div className="main-card text-center">
                    <div className="borderbox">
                      <h5>Comfort+</h5>
                      <span className="seat-icon" style={{ fontSize: "30px" }}>
                        <MdAirlineSeatReclineExtra />
                      </span>
                    </div>
                  </div>
                  <div className="main-card text-center">
                    <div className="borderbox">
                      <h5>Premium Select</h5>
                      <span className="seat-icon" style={{ fontSize: "30px" }}>
                        <MdAirlineSeatReclineExtra />
                      </span>
                    </div>
                  </div>
                  <div className="main-card text-center">
                    <div className="borderbox">
                      <h5>Flight One</h5>
                      <span className="seat-icon" style={{ fontSize: "30px" }}>
                        <MdAirlineSeatReclineExtra />
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {Object.keys(flightsData?.data || {}).length ? (
          Object.keys(flightsData?.data || {})?.map((flight: any) => {
            return (
              <FlightCard
                flight={flight}
                details={flightsData?.data[flight]}
                dictionaries={flightsData?.dictionaries}
              />
            )
          })
        ) : (
          <h1>Flights are getting fetched, please wait for a few seconds.</h1>
        )}
      </section>
      <Footer />
    </>
  )
}

export default FlightPage
