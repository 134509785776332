import React from "react"
import "./Header.css"
import {
  Button,
  Container,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
} from "react-bootstrap"

import { Form, useNavigate } from "react-router-dom"

const Header = () => {
  const navigate = useNavigate()
  return (
    <>
      {/* <h6 onClick={() => navigate("/")}>
        Air Line
      </h6> */}
    </>
  )
}

export default Header
