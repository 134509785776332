import React from "react"
import './footer.css'
import { Container, ListGroup, Navbar, Nav } from "react-bootstrap";
import { AiOutlineSearch } from 'react-icons/ai'
import { AiFillFacebook } from 'react-icons/ai'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { GiEarthAfricaEurope } from 'react-icons/gi'
import { useState } from 'react';
import ModalDetails from "../ModalDetails/ModalDetails";

const Footer = (props: any) => {
    return (
        <>
            <section className="footer-section py-5">
                <div className=" search-section">
                    <div className="search-bar">
                        <ListGroup >
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: 'none', color: 'white', textAlign: 'center', fontSize: '50px' }}>Search for a topic... <AiOutlineSearch className=" h1 " /></ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="nav-links">
                        <Navbar bg="tranparent" data-bs-theme="dark">
                            <Container>
                                <Nav className="m-auto">
                                    <Nav.Link href="#home">Popular Topics:</Nav.Link>
                                    <Navbar.Brand href="#home">Help center</Navbar.Brand>
                                    <Navbar.Brand href="#features">Discover Map</Navbar.Brand>
                                    <Navbar.Brand href="#pricing">eCredits</Navbar.Brand>
                                </Nav>
                            </Container>
                        </Navbar>
                    </div>
                </div>
                <hr className="new1 my-5">
                </hr>
                <Container>
                    <div className="footer-nav d-md-flex justify-content-between">
                        <div className="about-delta">
                            <ul style={{ listStyleType: 'none', padding: '0' }}>
                                <li style={{ color: '#7d8388', fontWeight: 'bold', fontSize: '20', padding: '20px 0px' }}>ABOUT</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>About Us</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Careers</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>News Hub</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Investor Relations</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Business Travel</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Travel Agents</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Mobile App</li>
                            </ul>
                        </div>
                        <div className="customer-service">
                            <ul style={{ listStyleType: 'none', padding: '0' }}>
                                <li style={{ color: '#7d8388', fontWeight: 'bold', fontSize: '20', padding: '20px 0px' }}>CUSTOMER SERVICE</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Help Center</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Message Us</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Comment/Complaint</li>
                            </ul>
                        </div>
                        <div className="site-support">
                            <ul style={{ listStyleType: 'none', padding: '0' }}>
                                <li style={{ color: '#7d8388', fontWeight: 'bold', fontSize: '20', padding: '20px 0px' }}>SITE SUPPORT</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Login Help</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Site Map</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Browser Compatibility</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Accessibility</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Booking Information</li>
                            </ul>
                        </div>
                        <div className="policies">
                            <ul style={{ listStyleType: 'none', padding: '0' }}>
                                <li style={{ color: '#7d8388', fontWeight: 'bold', fontSize: '20', padding: '20px 0px' }}>POLICIES</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Customer Commitment</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Tarmac Delay Plan</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Legal</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Sustainability</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Contract of Carriage</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Cookies, Privacy & Security</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>Human Trafficking Statement</li>
                                <li style={{ color: 'white', fontWeight: 'bold', fontSize: '20', paddingBottom: '20px' }}>(PDF)</li>
                            </ul>
                        </div>
                    </div>
                </Container>
                <div className="copyright-content" style={{ textAlign: 'center', color: '#7d8388' }}>
                    <p>© 2023 Air Line, Inc. | Travel may be on other airline.</p>
                    <p>Terms and conditions apply to all offers and Airline benefits. See specific offer for details, and visit <span className="heading-color" style={{ color: 'white' }}>Airline Membership Guide & Program Rules</span></p>
                </div>
                <div className="media-icon">
                    <div className="social-media">
                        <ListGroup horizontal>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: 'none', color: 'white', fontSize: '30px' }}><AiFillFacebook /></ListGroup.Item>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: 'none', color: 'white', fontSize: '30px' }}><AiFillTwitterCircle /></ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="language">
                        <ListGroup horizontal>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', border: 'none', color: 'white', }}><span className="icon"><GiEarthAfricaEurope /></span>VACATION DEALS</ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            </section>
            {/* <ModalDetails show={show} handleClose={handleClose} /> */}
        </>)
}

export default Footer
