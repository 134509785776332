import { createSlice } from "@reduxjs/toolkit";

type FlightsSlice = {
	flights: [] | null;
};

const flightsSlice = createSlice({
	name: "flights",
	initialState: { flights: [] } as FlightsSlice,
	reducers: {
		logout(state) {
			state.flights = null;
		},
	}
});

export const { logout } = flightsSlice.actions;
export default flightsSlice.reducer;
